<template>
	<div class="container">
		<div class="title-wrap">
			<h2 class="title">{{ boardName }}</h2>  <el-button @click="$router.push('/board/'+ $route.params.id +'/write')" v-if="canWrite" type="primary" style="display: inline-block; float: right;">작성</el-button>
		</div>
    <template v-for="(item, idx) in articles">
    <el-card :key="idx" class="box-card" @click.native="$router.push('/board/'+ $route.params.id +'/' + item.idx)" shadow="hover"> 
        <span><b>{{ item.title }}</b></span>
        <span style="float: right;"><i class="el-icon-user-solid"></i>&nbsp;{{ item.writer }}&nbsp;&nbsp;&nbsp;&nbsp;<i class="el-icon-alarm-clock"></i>&nbsp;{{ item.time }}</span>
    </el-card>
    </template>
    
    <el-pagination v-if="table_load" layout="prev, pager, next" :total="total" :page-size="15" :current-page="page" @current-change="changePage"> </el-pagination>
 
	</div>
</template>

<script>
import axios from "axios";
import {apiUrl} from '../constants/config';
import { Loading } from 'element-ui';

var loadingInstance;

export default {
	data() {
		return {
			table_load: false,
      page: 1,
      total: 0,
      canWrite: false,
      boardName: "",
      articles: [

      ]
		}
	},
	methods: {
		loadTable(){
			var vm = this;
			vm.table_load = false;
			axios.post(apiUrl+'/board/'+this.$route.params.id+'/list',{
				page: vm.page
			}, { withCredentials: true })
			.then(function(response) {
        if(response.data.success){
          vm.articles = response.data.articles;
          vm.total = response.data.total;
          vm.table_load = true;
          loadingInstance.close();
        }
        else{
        vm.$router.push(response.data.redirect);
        }
			})
			.catch(function(error) {
				console.log(error);
			});
		},
    changePage(page){
			this.page = page;
			this.loadTable();
		}
	},
	mounted(){
		this.loadTable();
	},
  beforeCreate(){
    loadingInstance = Loading.service({ fullscreen: true });

    var vm = this;
    axios.get(apiUrl+'/board/'+this.$route.params.id+'/', { withCredentials: true })
    .then(function(response) {
      if(response.data.success){
        vm.canWrite = response.data.canWrite;
        vm.boardName = response.data.boardName;
      }
      else{
      vm.$router.push("/");
      }
    })
    .catch(function(error) {
      console.log(error);
    });
  },
  watch: {
    '$route.params.id': {
      handler (oldUrl, newUrl) {
        loadingInstance = Loading.service({ fullscreen: true });

    var vm = this;
    axios.get(apiUrl+'/board/'+this.$route.params.id+'/', { withCredentials: true })
    .then(function(response) {
      if(response.data.success){
        vm.canWrite = response.data.canWrite;
        vm.boardName = response.data.boardName;
        vm.loadTable();
      }
      else{
      vm.$router.push("/");
      }
    })
    .catch(function(error) {
      console.log(error);
    });
      }
    }
  }
}
</script>

<style scoped>
.box-card {
  margin-bottom: 15px;
  cursor: pointer;
}


</style>
